<script>
    import Monitor from "@/services/Monitor";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import _ from 'underscore';

    export default {
        components:{
            vueDropzone: vue2Dropzone,
        },
        props: {
            sportTeamData: {
                type: [Array, Object],
                default: () => ({})
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem("csrf_token"),
                showModal: false,
                tryingToEdit: false,
                status_new: 0,
                show_image: false,
                show_competition_logo_button : false ,
                file_e: '',
                home_shirt: '',
                away_shirt: '',
                teams : [] ,
                photoDeleted: false,
                photoDeletedAway: false,
                photoDeletedHome: false,
                errorMsg : '' ,
                team_name_key : 0 ,
                secondModalVisible : false ,
                showAddRelatedTeamModal : false ,
                loading : null ,
                TeamsData : [] ,
                isLoading: false,
                selectedTeams: [],
                team_name : '' ,
                filterInput: {
                    team_name: ''
                },
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },

            };
        },


        methods: {
            refreshData() {
                this.$emit('onRefresh');
            },


            async updateCompetitionLogo() {
                this.tryingToEdit = true;
                const id = this.sportTeamData.competition_id;

                const data = {

                    competition_logo: this.file_e ,
                };
                try {
                    await Monitor.updateCompetitionLogo(id, data,{
                    }).then((response) => {
                        this.successmsg("Change successfully saved");
                        this.refreshData()
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Failed to save changes")
                    }).finally(() => {
                        this.tryingToEdit = false;
                    })
                }
                catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },

            closeModal() {
                this.showModal = false;
                this.photoDeleted = false;

                this.file_e = '';
                this.$emit("onCancel");
                this.$bvModal.hide("edit_sport_team");
                this.refreshData();
            },
            handleModalClose() {
                this.refreshData();
                this.photoDeleted = false;
                this.file_e = '';
            },
            deleteImage() {
                this.photoDeleted = true;
                this.file_e = '1';
                this.sportTeamData.competition_logo = '';
            },
            selectFile(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary.removeFile(value);
                        });
                    }
                    this.file_e = queuedFiles[0];
                    this.photoDeleted = false;
                }, 250);
            },
               }
    };
</script>

<template>
    <div>
        <b-modal v-model="showModal" id="edit_sport_competition" title="Edit Competition Logo" title-class="font-18" centered size="lg"  @hide="handleModalClose">
            <form @submit.prevent="updateCompetitionLogo">
                <div class="row">
                    <div class="form-group col-lg-8" style="font-weight: 500; margin-bottom: 10px">
                        <input type="text" id="competition_id" class="form-control" style="display: none;" v-model="sportTeamData.competition_id" />

                        <div style=" margin-bottom: 3px;">Competition ID : {{sportTeamData.competition_id}} </div>
                        <div  style=" margin-bottom: 3px;"> Competition Name : {{sportTeamData.competition_name}} </div>
                        <div  style=" margin-bottom: 3px;">Competition Code : {{sportTeamData.competition_code}} </div>
                        <div>Provider : {{sportTeamData.provider}} </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="competition_logo">Team Logo:</label>
                    <template v-if="!photoDeleted && sportTeamData.competition_logo">
                        <b-form-group label-for="image" class="mb-3 text-center image-area">
                            <img :src="sportTeamData.competition_logo" alt="" width="200" class="img-thumbnail" />
                        </b-form-group>
                        <div class="text-center mt-3">
                            <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImage">
                                <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                        </div>
                    </template>
                    <template v-else>
                        <vue-dropzone
                            id="dropzone_primary"
                            ref="myVueDropzone_primary"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFile"
                            no-progress-bar
                        >
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop file here or click to upload.</h4>
                            </div>
                        </vue-dropzone>
                    </template>
                </div>
            </form>

            <template v-slot:modal-footer>
                <b-button variant="light" @click="closeModal">Close</b-button>
                <b-button variant="primary" @click="updateCompetitionLogo" :disabled="tryingToEdit">
                    <b-spinner v-show="tryingToEdit" small></b-spinner>
                    Save
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

